<template>
  <b-card title="Events" class="event-table">
      <b-button size="md" variant="primary" @click="add()" v-if="$can('add', 'events')">
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        /><span>Add</span>
      </b-button>
    
    <!-- search input -->
    <div class="custom-search float-right">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            name="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :sort-options="{
        enabled: true,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Registered Users -->
        <span
          v-if="props.column.field === 'registers_count'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Checked In Users -->
        <span
          v-if="props.column.field === 'checked_in_count'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Link -->
        <span
          v-if="props.column.field === 'link'"
        class="text-nowrap"
        >
        <p>
          <b>Registration Link:</b><br>
          <a :href="'' + props.row.url + ''" target="_blank">{{ props.row.url }}</a>
        </p>
        <p>
          <b>Feedback Form Link:</b><br>
          <a :href="'' + props.row.feedback_url + ''" target="_blank" v-if="props.row.questions.length > 0">{{ props.row.feedback_url }}</a>
          <div v-if="props.row.questions.length == 0">-</div>
        </p>
        </span>

        <!-- Column: Event Date Start -->
        <span
          v-if="props.column.field === 'date'"
          class="text-nowrap"
        >
          <p>
            <b>Event Start Date:</b><br>
            {{ props.row.date_start }} 
          </p>
          <p>
            <b>Event End Date:</b><br>
            {{ props.row.date_end }} 
          </p>
        </span>

        <span
          v-if="props.column.field === 'participant_info'"
          class="text-nowrap"
        >
          <p>
            <b>Total Registrant:</b><br>
            {{ props.row.registers_count }} Person
          </p>
          <p>
            <b>Total Checked In:</b><br>
            {{ props.row.checked_in_count }} Person
          </p>
        </span>

        <!-- Column: Location -->
        <span
          v-if="props.column.field === 'location'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Updated at -->
        <span
          v-if="props.column.field === 'updated_at'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Created by -->
        <span
          v-if="props.column.field === 'update'"
          class="text-nowrap"
        >
          <p>
            <b>Date:</b><br>
            {{ props.row.updated_at }} 
          </p>
          <p>
            <b>By:</b><br>
            <span class="text-nowrap" v-if="props.row.updated_by !== null">{{ props.row.updated_by.name }}</span>
            <span class="text-nowrap" v-if="props.row.created_by !== null && props.row.updated_by === null">{{ props.row.created_by.name }}</span>
            <span class="text-nowrap" v-if="props.row.created_by === null && props.row.updated_by === null">-</span>
          </p>  
         
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="view(props.row.id)" v-if="$can('view', 'events')">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>View</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewFields(props.row.id)" v-if="$can('view', 'fields')">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Custom Fields</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewFormSetting(props.row.id)" v-if="$can('view', 'fields')">
                <feather-icon
                  icon="SettingsIcon"
                  class="mr-50"
                />
                <span>Form Settings</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewParticipants(props.row.id)" v-if="$can('view', 'event_participants')">
                <feather-icon
                  icon="UserIcon"
                  class="mr-50"
                />
                <span>Participants</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewBooths(props.row.id)" v-if="$can('view', 'event_booths')">
                <feather-icon
                  icon="ArchiveIcon"
                  class="mr-50"
                />
                <span>Booths</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewScans(props.row.id)" v-if="$can('view', 'event_scans')">
                <feather-icon
                  icon="RadioIcon"
                  class="mr-50"
                />
                <span>Absensi</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewStats(props.row.id)" v-if="$can('view', 'event_stats')">
                <feather-icon
                  icon="PieChartIcon"
                  class="mr-50"
                />
                <span>Reporting</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewManagers(props.row.id)" v-if="$can('view', 'event_managers')">
                <feather-icon
                  icon="KeyIcon"
                  class="mr-50"
                />
                <span>Client Access</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewFeedbackForms(props.row.id)" v-if="$can('view', 'questions')">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-50"
                />
                <span>Feedback Form</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewEmails(props.row.id)" v-if="$can('view', 'event_emails')">
                <feather-icon
                  icon="MailIcon"
                  class="mr-50"
                />
                <span>Email</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewAdditionalDataReport(props.row.id)" v-if="$can('view', 'event_participants')">
                <feather-icon
                  icon="PieChartIcon"
                  class="mr-50"
                />
                <span>Additional Data Report</span>
              </b-dropdown-item>
              <b-dropdown-item @click="edit(props.row.id)" v-if="$can('edit', 'events')">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeModal(props.row.id, props.row.name)" v-if="$can('delete', 'events')">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  <!-- Modals -->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Delete {{ pageName }}</h5>
      </template>

      <p>
        Are you sure you want to delete event <b>"{{ currentObject.eventName }}"</b>?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number',
            width: '5%',
        },
        {
          label: 'Name',
          field: 'name',
          width: '25%',
        },
        {
          label: 'Link',
          field: 'link',
          width: '20%',
        },
        {
          label: 'Participant Info',
          field: 'participant_info',
          width: '15%',
        },
        {
          label: 'Event Date',
          field: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
          width: '15%',
        },
        // {
        //   label: 'Location',
        //   field: 'location',
        // },
        {
          label: 'Last Updated',
          field: 'update',
          width: '15%',
        },
        {
          label: 'Action',
          field: 'action',
          width: '5%',
        },
      ],
      rows: [],
      searchTerm: '',

      isLoadingTable: false,
      isLoadingDelete: false,
      showDeleteModal: false,
      pageName: "Event",

      currentObject: {
        id: -1,
        eventName: ""
      }
    }
  },
  computed: {
  },
  methods: {
    formatDate(value) {
      return moment(String(value), 'YYYY-MM-DD H:mm:s').format('DD-MM-YYYY H:mm')
    },
    add() {
      this.$router.push({ name: 'main-events-add' })
    },
    edit(id) {
      // this.$router.push('/events/' + id + '/edit')
      const routeData = this.$router.resolve({path: '/events/' + id + '/edit', query: {}})
      window.open(routeData.href, '_blank')
    },
    view(id) {
      //this.$router.push('/events/' + id + '/view')
      const routeData = this.$router.resolve({path: '/events/' + id + '/view', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewFields(id) {
      //this.$router.push('/events/' + id + '/fields')
      const routeData = this.$router.resolve({path: '/events/' + id + '/fields', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewFormSetting(id) {
      // this.$router.push('/events/' + id + '/form-setting')
      const routeData = this.$router.resolve({path: '/events/' + id + '/form-setting', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewFeedbackForms(id) {
      // this.$router.push('/events/' + id + '/questions')
      const routeData = this.$router.resolve({path: '/events/' + id + '/questions', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewBooths(id) {
      // this.$router.push('/events/' + id + '/booths')
      const routeData = this.$router.resolve({path: '/events/' + id + '/booths', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewStats(id) {
      // this.$router.push('/events/' + id + '/stats')
      const routeData = this.$router.resolve({path: '/events/' + id + '/stats', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewScans(id) {
      // this.$router.push('/events/' + id + '/scans')
      const routeData = this.$router.resolve({path: '/events/' + id + '/scans', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewParticipants(id) {
      // this.$router.push('/events/' + id + '/participants')
      const routeData = this.$router.resolve({path: '/events/' + id + '/participants', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewManagers(id) {
      // this.$router.push('/events/' + id + '/managers')
      const routeData = this.$router.resolve({path: '/events/' + id + '/managers', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewAdditionalDataReport(id) {
      // this.$router.push('/events/' + id + '/managers')
      const routeData = this.$router.resolve({path: '/events/' + id + '/report', query: {}})
      window.open(routeData.href, '_blank')
    },
    viewEmails(id) {
      // this.$router.push('/events/' + id + '/email')
      const routeData = this.$router.resolve({path: '/events/' + id + '/email', query: {}})
      window.open(routeData.href, '_blank')
    },
    removeModal(id,name) {      
      this.showDeleteModal = true

      this.currentObject.id = id
      this.currentObject.eventName = name
    },
    remove() {
      this.isLoadingDelete = true

      const id = this.currentObject.id

      this.$http.delete('/admin/v1/events/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

            this.showDeleteModal = false
          }

          this.isLoadingDelete = false

          this.fetchData()
      })
    },
    fetchData() {
      this.isLoadingTable = true

      this.$http.get('/admin/v1/events-ongoing')
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
          
            this.rows = currentData
          }

          this.isLoadingTable = false

     })
    }
  },
  created() {
    this.fetchData()
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.event-table .vgt-responsive {
  min-height: 500px;
}
</style>